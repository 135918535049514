import React from "react"
import styled from "@emotion/styled"
import { FaSearch } from "react-icons/fa"
import { theme } from "../particles/theme"

const StyledSection = styled.section`
  @media (max-width: ${theme.breakpoints.tablet}) {
    margin-bottom: 0 !important;
  }

  label {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    display: block;
  }

  input {
    border: ${(props: StyledProps) =>
      `${props.border.size}px ${props.border.type} ${props.border.color}`};
    padding: 1rem;
    display: block;
    width: calc(100% - 4rem);
    padding-left: 3rem;
    font-size: 1.6rem;
    outline: none;
    background-color: ${(props: StyledProps) => props.backgroundColor};
  }

  svg {
    color: ${theme.colors.homeHardware.gray};
    position: relative;
    top: -2.75rem;
    left: 1rem;
  }
`

const Input = ({
  label,
  value,
  name,
  placeholder,
  border = { size: 1, type: `solid`, color: `black` },
  backgroundColor = "rgb(248, 248, 248)",
  onChange,
}: Props) => {
  return (
    <StyledSection border={border} backgroundColor={backgroundColor}>
      {label ? <label htmlFor={name}>{label}</label> : null}
      <input
        type="text"
        name={name}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
      />
      <FaSearch />
    </StyledSection>
  )
}
type Border = {
  size: number
  type: string
  color: string
}

type Props = {
  label: string
  value: string
  name: string
  placeholder: string
  border?: Border
  backgroundColor?: string
  onChange?: () => void
}

type StyledProps = {
  border: Border
  backgroundColor: string
}

export default Input
